import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['button', 'label'];

  connect() {
    this.buttonTargets.forEach((el, i) => {
      if (el.checked) {
        var selector = 'label[for=' + el.id + ']'
        var label = document.querySelector(selector);
        label.classList.add('bg-gray-500')
      }
    })
  }

  radioChanged(ev) {
    this.buttonTargets.forEach((el, i) => {
      if (el.checked) {
        var selector = 'label[for=' + el.id + ']'
        var label = document.querySelector(selector);
        label.classList.add('bg-gray-500')
      } else {
        var selector = 'label[for=' + el.id + ']'
        var label = document.querySelector(selector);
        label.classList.remove('bg-gray-500')
      }
    })
  }
}
