import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['checkBox','label'];

  connect() {
    this.checkBoxTargets.forEach((el, i) => {
      if (el.checked) {
        var selector = 'label[for=' + `"${el.id}"` + ']'
        var label = document.querySelector(selector);
        label.classList.add('bg-gray-500')
      }
    })
  }

  checked() {
    if (this.checkBoxTarget.checked) {
      this.labelTarget.classList.add('bg-gray-500')
    } else {
      this.labelTarget.classList.remove('bg-gray-500')
    }
  }
}
