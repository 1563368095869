import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['textField', 'checkBox', 'textFieldSection', 'checkBoxSection', 'textFieldSectionButton', 'checkBoxSectionButton'];

  connect() {
  }

  showCheckBoxSection() {
    this.checkBoxSectionButtonTarget.classList.add('hidden');
    this.textFieldSectionButtonTarget.classList.remove('hidden');

    this.checkBoxSectionTarget.classList.remove('hidden');
    this.textFieldSectionTarget.classList.add('hidden');
    this.textFieldTarget.value = '';
    this.checkBoxTarget.value = '1';
  }

  showTextFieldSection() {
    this.textFieldSectionButtonTarget.classList.add('hidden');
    this.checkBoxSectionButtonTarget.classList.remove('hidden');

    this.textFieldSectionTarget.classList.remove('hidden');
    this.checkBoxSectionTarget.classList.add('hidden');
    this.checkBoxTarget.value = '0';
  }
}
